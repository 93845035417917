import React from 'react';
import { Link } from '@/Framework/Router/Next/Link';
import SharedCustomers from '@/ui/shared/components/Landing/Sections/Customers';
import Button from '@/ui/shared/components/Button';
import Section from '@/ui/shared/components/Landing/Sections/Section';
import SectionContainer from '@/ui/shared/components/Landing/Sections/SectionContainer';
import SectionHeader from '@/ui/shared/components/Landing/Sections/SectionHeader';
import URL from '@/evercall/Router/urlConstants';
import customers from './constants';
import styles from './customers.scss';
import { CUSTOMERS_SECTION_ID } from '@/evercall/ui/components/Features/Customers/constants';

const Customers = () => (
  <Section padding="top" dataTest="customersSection">
    <SectionContainer size="large">
      <SectionHeader
        title="Experience a new standard for moderated events."
        description="Hundreds of the world’s leading investment banks and corporations trust Evercall to empower their most critical conversations."
        className={ styles.headerWrapper }
        maxWidth={ 800 }
      />
      <SharedCustomers
        customers={ customers }
        showMoreButton={ (buttonProps) => (
          <Link
            to={ `${ URL.FEATURES }#${ CUSTOMERS_SECTION_ID }` }
            data-test="exploreOurCustomers"
          >
            <Button
              { ...buttonProps }
              title="Explore Our Customers"
              dataTest="exploreOurCustomersButton"
            />
          </Link>
        ) }
      />
    </SectionContainer>
  </Section>
);

export default Customers;
