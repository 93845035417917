import { ICustomer } from '@/ui/shared/components/Landing/Sections/Customers';
import airFranceLogo from '@/ui/assets/customers/svg/airFrance.svg';
import americanAirlinesLogo from '@/ui/assets/customers/svg/americanAirlines.svg';
import barclaysLogo from '@/ui/assets/customers/svg/barclays.svg';
import bnpParibasLogo from '@/ui/assets/customers/svg/bnpParibas.svg';
import chuckCheeseLogo from '@/ui/assets/customers/svg/chuckCheese.svg';
import creditAgricoleLogo from '@/ui/assets/customers/svg/creditAgricole.svg';
import cocaColaLogo from '@/ui/assets/customers/svg/cocaCola.svg';
import daimlerTruckLogo from '@/ui/assets/customers/svg/daimlerTruck.svg';
import dardenRestaurantsLogo from '@/ui/assets/customers/svg/dardenRestaurants.svg';
import delMonteLogo from '@/ui/assets/customers/svg/delMonte.svg';
import deutscheBankLogo from '@/ui/assets/customers/svg/deutscheBank.svg';
import enterpriseLogo from '@/ui/assets/customers/svg/enterprise.svg';
import fannieMaeLogo from '@/ui/assets/customers/svg/fannieMae.svg';
import goldmanSachsLogo from '@/ui/assets/customers/svg/goldmanSachs.svg';
import jefferiesLogo from '@/ui/assets/customers/svg/jefferies.svg';
import HSBCLogo from '@/ui/assets/customers/svg/HSBC.svg';
import lendleaseLogo from '@/ui/assets/customers/svg/lendlease.svg';
import mizuhoLogo from '@/ui/assets/customers/svg/mizuho.svg';
import morrisonsLogo from '@/ui/assets/customers/svg/morrisons.svg';
import mufgLogo from '@/ui/assets/customers/svg/mufg.svg';
import nationalBankOfCanadaLogo from '@/ui/assets/customers/svg/nationalBankOfCanada.svg';
import nationalGridLogo from '@/ui/assets/customers/svg/nationalGrid.svg';
import nationWideLogo from '@/ui/assets/customers/svg/nationWide.svg';
import nomuraLogo from '@/ui/assets/customers/svg/nomura.svg';
import peletonLogo from '@/ui/assets/customers/svg/peloton.svg';
import raymondJamesLogo from '@/ui/assets/customers/svg/raymondJames.svg';
import sothebysLogo from '@/ui/assets/customers/svg/sothebys.svg';
import toyotaLogo from '@/ui/assets/customers/svg/toyota.svg';

const customers: ICustomer[] = [
  {
    src: airFranceLogo,
    title: 'Air France',
    width: 115,
    height: 19,
  },
  {
    src: americanAirlinesLogo,
    title: 'American Airlines',
    width: 81,
    height: 30,
  },
  {
    src: barclaysLogo,
    title: 'Barclays',
    width: 115,
    height: 31,
  },
  {
    src: bnpParibasLogo,
    title: 'BNP PARIBAS',
    width: 115,
    height: 25,
  },
  {
    src: chuckCheeseLogo,
    title: 'Chuck E. Cheese',
    width: 81,
    height: 30,
  },
  {
    src: creditAgricoleLogo,
    title: 'Credit Agricole',
    width: 115,
    height: 27,
  },
  {
    src: cocaColaLogo,
    title: 'Coca Cola',
    width: 78,
    height: 30,
  },
  {
    src: daimlerTruckLogo,
    title: 'Daimler Truck',
    width: 87,
    height: 30,
  },
  {
    src: dardenRestaurantsLogo,
    title: 'Darden Restaurants',
    width: 102,
    height: 31,
  },
  {
    src: delMonteLogo,
    title: 'Del Monte',
    width: 41,
    height: 30,
  },
  {
    src: deutscheBankLogo,
    title: 'Deutsche Bank',
    width: 115,
    height: 19,
  },
  {
    src: enterpriseLogo,
    title: 'Enterprise',
    width: 115,
    height: 24,
  },
  {
    src: fannieMaeLogo,
    title: 'Fannie Mae',
    width: 115,
    height: 31,
  },
  {
    src: goldmanSachsLogo,
    title: 'Goldman Sachs',
    width: 71,
    height: 31,
  },
  {
    src: HSBCLogo,
    title: 'HSBC',
    width: 115,
    height: 31,
  },
  {
    src: jefferiesLogo,
    title: 'Jefferies',
    width: 115,
    height: 31,
  },
  {
    src: lendleaseLogo,
    title: 'Lendlease',
    width: 42,
    height: 30,
  },
  {
    src: mizuhoLogo,
    title: 'Mizuho',
    width: 99,
    height: 30,
  },
  {
    src: morrisonsLogo,
    title: 'Morrisons',
    width: 59,
    height: 30,
  },
  {
    src: mufgLogo,
    title: 'MUFG',
    width: 115,
    height: 28,
  },
  {
    src: nationalBankOfCanadaLogo,
    title: 'National bank of Canada',
    width: 87,
    height: 31,
  },
  {
    src: nationalGridLogo,
    title: 'National grid',
    width: 116,
    height: 25,
  },
  {
    src: nationWideLogo,
    title: 'Nation wide',
    width: 30,
    height: 30,
  },
  {
    src: nomuraLogo,
    title: 'Nomura',
    width: 115,
    height: 31,
  },
  {
    src: peletonLogo,
    title: 'Peleton',
    width: 103,
    height: 30,
  },
  {
    src: raymondJamesLogo,
    title: 'Raymond James',
    width: 115,
    height: 31,
  },
  {
    src: sothebysLogo,
    title: "Sotheby's",
    width: 116,
    height: 28,
  },
  {
    src: toyotaLogo,
    title: 'Toyota',
    width: 115,
    height: 31,
  },
];

export default customers;
